@font-face {
    font-family: Round;
    src: url("../../../fonts/Round.otf");
}

.myButton{
    transition: all 0.5s;
}

.myButton:hover{
    transform: translateY(-5px);
}