@font-face {
    font-family: Round_Light;
    src: url("../../fonts/Round_Light.otf");
}
@font-face {
    font-family: Round;
    src: url("../../fonts/Round.otf");
}

.tipografiaLight{
    font-family: "Round_Light";
}

.tipografia{
    font-family: "Round";
}

.flotando{
    position: sticky;
}