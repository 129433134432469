@font-face {
    font-family: Round;
    src: url("../../fonts/Round.otf");
}

@font-face {
    font-family: RoundLight;
    src: url("../../fonts/Round_Light.otf");
}

.secPlatfomrIO{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textPlatformio{
    width: 85%;
    text-align: left;   
}

.imgPonentePlatformIO{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.imgPonentePlatformIO img{
    width: 30%;
    height: auto;
    border-radius: 1hv;
    margin: 10px;
}
.imgPonentePlatformIO2{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.imgPonentePlatformIO2 img{
    width: 50%;
    height: auto;
    border-radius: 1hv;
    margin: 10px;
}
