@font-face {
    font-family: Round;
    src: url("../../fonts/Round.otf");
}

.contenedorSobreImg{
    height: 100%;
    width: 100%;
    font-family: "Round";
    display: flex;
    flex-direction: column;
}

.tituloInicio{

    width: 100%;
    height: auto;
}

.texto{
    font-size: 2vw;
}

.imgCursosInicio{
    width: 100vw;
    height: 89vh;
}

.contenedorBtn{
    width: 100%;
    margin-top: 15px;
}

.myButton{
    transition: all 0.5s;
}

.myButton:hover{
    transform: translateY(-5px);
}

.borde{
    border-color: rgb(190, 0, 0);
    border-width: 2px;
    border-style: solid;
}