.fondo {
	display: grid;
	place-items: center;
	height: 100vh;
	background: linear-gradient(0deg, #1b1e26 6%, #303440 95%);
	font-family: Arial, Helvetica, sans-serif;
	color: #fff;
}

.login {
	position: relative;
}

.login:before,
.login__circuit-mask {
	content: '';
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	width: 682px;
	height: 514px;
}

.login:before {
	background: url(./bg-circuito.svg);
}

.login__circuit-mask {
	display: grid;
	place-items: center;
	-webkit-mask: url(./bg-circuito.svg);
	mask: url(./bg-circuito.svg);
}

.login__circuit-mask:before {
	content: '';
	position: absolute;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 3px solid #00f0ff;
	animation: onda 4s infinite;
	animation-delay: 1s;
}

.login__heading {
	text-align: center;
}

@keyframes onda {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(8);
	}
	100% {
		transform: scale(8);
	}
}