@font-face {
    font-family: Round;
    src: url("../../fonts/Round.otf");
}

.contenido{
    width: 80%;
    height: 100%;
    padding: 0px 30px;
    margin-left: 20%;
}

.menu{
    width: 20%;
    height: auto;
    padding-left: 10px;
    font-family: Round;
    font-size: 1.5vw;
    padding-left: 20px;
}

.Link{
    transition: all 0.5s;
}
.Link:hover{
    transform: scale(1.05);
    
}

.titulo{
    width: 100%;
    height: auto;
    font-family: Round;
    font-size: 5vw;
    display: flex;
    margin-top: 15px;
    justify-content: center;
}

.subTitulo{
    width: 100%;
    height: auto;
    font-family: Round;
    font-size: 3vw;
    position: relative;
    display: flex;
    justify-content: flex-start;
}

.texto{
    width: 100%;
    height: auto;
    font-family: Round;
    font-size: 2vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 5px;
}

.textoContenido{
    width: 100%;
    height: auto;
    font-family: Round;
    font-size: 1.4vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.codigoStyle{
    width: fit-content;
    height: auto;
    font-family: Consolas,"courier new";
    color: crimson;
    background-color: #bebebe;
    background: coral;
    font-size: 1.4vw;
}

.seccion{
    margin-top: 35px;
}

.border{
    border-style: solid;
    border-width: 1px;
    border-color: black;

}